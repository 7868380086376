// This address points to a dummy ERC20 contract deployed on Ethereum Mainnet,
// Goerli, Kovan, Rinkeby and Ropsten. Replace it with your smart contracts.
// const addresses = {
//   ceaErc20: "0xc1C0472c0C80bCcDC7F5D01A376Bd97a734B8815",
// };
import addresses from "./abis/addresses.json";
import addressesLocalhost from '../data/addresses1337.json';
import addressesDeploy from "../data/addressesdeploy.json";

// console.log('address say you are on CHAIN ID: ',window.chainId)


export default addressesLocalhost;
