import React from "react";
import { ButtonBase, Grid, Box,  Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import * as COLORS from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    "&:hover, &$focusVisible": {
      backgroundColor: COLORS.grey[200],
    },
  },
  coinName: {
    opacity: 0.6,
  },
  coinIcon:{
    width:'100%',
    height:'auto',
    transform:'scale(0.75)'
  },
  coinHolder : {
    width:"200px"
  }
}));

CoinButton.propTypes = {
  coinName: PropTypes.string.isRequired,
  coinAbbr: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default function CoinButton(props) {
  const { coinName, coinAbbr, onClick, ...other } = props;
  const classes = useStyles();

  return (
    <ButtonBase focusRipple className={classes.button} onClick={onClick} sx={{ justifyContent: 'flex-start' }}>
      
      <Box className={classes.coinHolder} sx={{ paddingLeft:'50px' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <img
              src={`/coins/${coinAbbr.toLocaleLowerCase()}.png`}
              alt={coinAbbr}
              loading="lazy"
              className={classes.coinIcon}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container direction="column" sx={{textAlign:'left'}}>
              <Typography variant="h6">{coinAbbr}</Typography>
              <Typography variant="body2" className={classes.coinName}>
                {coinName}
              </Typography>
            </Grid>
          </Grid>
          
        </Grid>
      </Box>
      {/* <Grid container direction="column">
        
        
      </Grid> */}
    </ButtonBase>
  );
}
