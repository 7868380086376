import { useQuery } from "@apollo/react-hooks";
import { Contract } from "@ethersproject/contracts";
import { getDefaultProvider,Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";

import { Body, Header, Image, Link } from "./components";
import NavBar from "./components/Navbar";
import logo from "./ethereumLogo.png";
import useWeb3Modal from "./hooks/useWeb3Modal";

import { addresses,deployAddresses, abis } from "@project/contracts";
import COINS from "./constants/coins";
import GET_TRANSFERS from "./graphql/subgraph";

import { Button, CardContent,Card,Typography } from '@mui/material';
import { SnackbarProvider } from "notistack";
import { Route, Routes } from "react-router-dom";
import Bridge from './components/pUSDBridge'
import Staking from './components/Staking'
import FlashStaking from './components/FlashStaking'


import Liquidity from "./Liquidity/Liquidity";
import Pools from "./Pools/Pools";
import CoinSwapper from "./CoinSwapper/CoinSwapper";



/*
WETH: https://oneclickdapp.com/field-garden
*/


// async function getPUSD(){

//   const provider = !!window.ethereum ? new Web3Provider(window.ethereum):getDefaultProvider();

//   const signer = await provider.getSigner();

//   const pusd = new Contract(addresses.PUSD,abis.PUSD,signer);

//   async function getBalance(){
//       return ethers.utils.formatEther(await pusd.balanceOf(signer.getAddress()))
//   }
  
//   console.log('PUSD balance: ',await getBalance());

// }

function WalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  const [account, setAccount] = useState("");
  const [rendered, setRendered] = useState("");

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }

        // Load the user's accounts.
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);

        setRendered(account.substring(0, 6) + "..." + account.substring(36));
      } catch (err) {
        setAccount("");
        setRendered("");
        console.error(err);
      }
    }
    fetchAccount();
  }, [account, provider, setAccount, setRendered]);

  return (
    <Button
    style={{
        // borderRadius: 35,
        backgroundColor: "#21b6ae",
        // padding: "18px 36px",
        // fontSize: "18px",
        color:"white"
    }}
    variant="contained"
      onClick={() => {
        if (!provider) {
          loadWeb3Modal();
        } else {
          logoutOfWeb3Modal();
        }
      }}
    >
      {rendered === "" && "Connect Wallet"}
      {rendered !== "" && rendered}
    </Button>
  );
}
window.loadingContracts = false;
function App() {
  const [isLoading,setLoading] = useState(true);
  const { loading, error, data } = useQuery(GET_TRANSFERS);
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  window.provider = provider;
  console.log(provider)
  if(provider&&!window.loadingContracts){
    window.loadingContracts = true;
    (async () =>{
      console.log('lol!')
      const signer = await provider.getSigner();
      const { chainId } = await provider.getNetwork();
      window.chainId = chainId;
      
      const chainAddresses = window.TESTING?addresses:deployAddresses;

      if(window.constants.chainIds['xdai']===chainId){
        window.xDaiBridge = new Contract(chainAddresses.XDAIBridge,abis.XDAIBridge,signer);
        window.xDaiBalance =  ethers.utils.formatEther(await signer.getBalance())
      }
      if(window.constants.chainIds['mintme']===chainId){
        window.mintmeBridge = new Contract(chainAddresses.MINTMEBridge,abis.MINTMEBridge,signer);
        window.pusd = new Contract(chainAddresses.PUSD,abis.PUSD,signer);
        window.pusdBalance = ethers.utils.formatEther(await window.pusd.balanceOf(signer.getAddress()));

       // window.masterChef = new Contract(chain)
      }
      
      
      window.loadingContracts = 'done';
      setLoading(false)
      if(window.ethereum){
        (async function(){
          console.log('THESE ARE THE COINS: ',COINS.get(chainId))
        })();
      }
      
    })();
    
  }
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  useEffect(()=>{
    window.forceUpdateUI = function(){
      forceUpdate();
    };
  },[]);
  
  React.useEffect(() => {
    if (!loading && !error && data && data.transfers) {
      console.log({ transfers: data.transfers });
    }
  }, [loading, error, data]);

  if(isLoading){
    return (
      <div>
      <NavBar>
        <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
      </NavBar>
      <Body>
        <Card>
          <CardContent >
            <Typography variant="h5" component="div" style={{textAlign:'center'}}>
              Please Connect Your Wallet to Use This Page
            </Typography>
            <br/>
            <Typography variant="body2" style={{textAlign:'center'}}>
              Click the "CONNECT WALLET" button in the top right to get started.
            </Typography>
            <br/>
            <Typography variant="body2" style={{textAlign:'center'}}>
              <b>Please Note that <Link href="https://metamask.io/">Metamask</Link> is required to use this dAPP</b>
            </Typography>
          </CardContent>
        </Card>
      </Body>
      </div>
      
    )
  }
  return (
    <div>
      <NavBar>
        <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
      </NavBar>
      <Body>
        {/* <Button hidden variant="contained" provider={provider} onClick={() => readOnChainData(provider)}>
          Check fees
        </Button>
        <Button hidden varient="contained" provider={provider} onClick={() => getPUSD()}>
          get PUSD balance
        </Button> */}
        <SnackbarProvider maxSnack={3}>
            <Routes>
              <Route path="liquidity" element={<Liquidity />} />
              <Route path="swap" element={<CoinSwapper />} />
              <Route path="bridge" element={<Bridge provider={provider} />} />
              <Route path="stake" element={<Staking provider={provider} />} />
              <Route path="lend" element={<FlashStaking provider={provider} />} />
              <Route path="pools" element={<Pools provider={provider} />} />
            </Routes>
          {/* <Liquidity></Liquidity> */}
        </SnackbarProvider>
        
        {/* {isLoading ? <p></p>:<Bridge provider={provider} />} */}
      </Body>
    </div>
  );
}
(function(){
  let provider = window.provider;
  if(!provider&&window.ethereum){
    provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  }
  if(provider){
    provider.on("network", (newNetwork, oldNetwork) => {
        console.log("new network",newNetwork, oldNetwork)
        if (oldNetwork) {
            window.location.reload();
        }
    });
  }
  if(window.ethereum){
    (async function(){
      console.log('THESE ARE THE COINS: ',COINS)
      // const wasAdded = await window.ethereum.request({
      //   method: 'wallet_watchAsset',
      //   params: {
      //     type: 'ERC20', // Initially only supports ERC20, but eventually more!
      //     options: {
      //       address: tokenAddress, // The address that the token is at.
      //       symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
      //       decimals: tokenDecimals, // The number of decimals in the token
      //       image: tokenImage, // A string url of the token logo
      //     },
      //   },
      // });
    })();
   
  }
  
})();
export default App;
