import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';

import { useSnackbar } from "notistack";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import styled from "styled-components";
import { Text, TextBold } from '../components/text';
import { ethers, utils } from 'ethers';
import Swal from 'sweetalert2'
import MasterChefAbi from '../constants/abis/MasterChef.json'
import ERC20Abi from '../constants/abis/IERC20.json'
import { Typography,Grid } from "@mui/material";

import { useCountUp } from 'react-countup';

export default function Staker(props){
    const { enqueueSnackbar } = useSnackbar();

    let [isbridging,setBridging] = useState(false);
    let [pending,setPending] = useState(null);
    const countUpRef = React.useRef(null);
    const { start, pauseResume, reset, update } = useCountUp({
        ref: countUpRef,
        start: 0,
        end: 0,
        delay: 1000,
        decimals:6,
        duration: 2,
        onReset: () => console.log('Resetted!'),
        onUpdate: () => console.log('Updated!'),
        onPauseResume: () => console.log('Paused or resumed!'),
        onStart: ({ pauseResume }) => console.log(pauseResume),
        onEnd: ({ pauseResume }) => console.log(pauseResume),
    });


    const hasFunds = true;
    const can = !isbridging;
    const fee = 0.1;
    const isStaking = props.coin==='pmint'
    let [qty,setQty] = useState(1);
    function changeQty(event){
        const target = event.target.value;
        if(isNaN(target-0)){return;}
        setQty(target);
    }
    
    async function BridgeToken(provider,coin,qty){
        setBridging(true);
        try {
            const signer = await props.provider.getSigner(); 
            const targetAddress = await signer.getAddress();
            const wei = ethers.utils.parseEther(qty+'');

            var lpt = new ethers.Contract(props.lpt,ERC20Abi.abi,signer)

            var staked = (await props.chef.userInfo(props.poolId,targetAddress)).amount;
            
            if(coin==='pmint'){
                if(wei.gt(await lpt.balanceOf(targetAddress))){
                    enqueueSnackbar("Insufficient Balance", {variant: "error",});
                    setBridging(false);
                    return;
                }
                const allowance = await lpt.allowance(targetAddress, props.chef.address);
                console.log(ethers.utils.formatEther(allowance),ethers.utils.formatEther(wei))
                const toApprove = wei.sub(allowance);
                if(toApprove.gt(0)){
                    const approval = await lpt.approve(props.chef.address, toApprove);
                    await approval.wait();
                }
                const tx = await props.chef.deposit(props.poolId,wei);
                await tx.wait();
            }else if(coin==='xpmint'){
                if(wei.gt(staked)){
                    enqueueSnackbar("Insufficient Balance", {variant: "error",});
                    setBridging(false);
                    return;
                }
                const tx = await props.chef.withdraw(props.poolId,wei);
                await tx.wait();
            }
            enqueueSnackbar("Transaction Successful", { variant: "success" });
        }catch(e){
            enqueueSnackbar("Transaction Failed (" + e.message + ")", {
                variant: "error",
              });
        }
        setBridging(false);
        
        return 0;
    }
    console.log('REEEE - > < - ',props.lpt,props.poolId,props.chef)
    async function useAll(){
        const signer = await props.provider.getSigner();
        const adr = await signer.getAddress();


        var lpt = new ethers.Contract(props.lpt,ERC20Abi.abi,signer)

        var staked = (await props.chef.userInfo(props.poolId,adr)).amount;
        
        console.log('dsfsdf -> ,',staked);

        if(isStaking){
            setQty(ethers.utils.formatEther(await lpt.balanceOf(adr)))
        }else{
            setQty(ethers.utils.formatEther(staked))
        }
        
        return 0;
    }

    useEffect(()=>{
        var sushiInterval;
        (async function(){
            const signer = await props.provider.getSigner();
            const adr = await signer.getAddress();

            async function fixSushi(first){
                var pendingSushi = await props.chef.pendingSushi(props.poolId, adr);

                console.log('pendingSushi -> ',ethers.utils.formatEther(pendingSushi));
                if(!pendingSushi.eq(0)){

                    setPending(ethers.utils.formatEther(pendingSushi))
                    update(ethers.utils.formatEther(pendingSushi)-0);
                    // if(first){
                    //     start(ethers.utils.formatEther(pendingSushi)-0)
                    // }
                }
            }
            fixSushi(true)

            sushiInterval = setInterval(function(){
                fixSushi()
            },5000)
            
        })();
        return () => clearInterval(sushiInterval);
        //pendingSushi(uint256 _pid, address _user)
    },[]);
    var coins = props.coins;

    return (
    <Box component="div" sx={{ '& > :not(style)': { m: 1 } }}>
        <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="qtyBridge">Amount</InputLabel>
            <OutlinedInput id="qtyBridge" value={qty} onChange={changeQty} startAdornment={
                <InputAdornment position="start">
                    <Grid item sx={{marginRight:2}}>
                        <img src={"/coins/"+coins[0].toLocaleLowerCase()+".png"} style={{ height: 30, width: 30,}} alt={coins[0]} />
                        <img src={"/coins/"+coins[1].toLocaleLowerCase()+".png"} style={{ height: 30, width: 30,marginLeft:-10}} alt={coins[1]} />
                    </Grid>
                    {/* <img src={"/coins/"+props.coin+".png"} style={{ height: 30, width: 30,}} alt={props.coin} /> */}
                </InputAdornment>
            } endAdornment={
                <Button onClick={useAll}>
                    All
                </Button>
            } label="Amount" />
            
        </FormControl>

        <FormControl fullWidth sx={{ m: 1 }}>
            <Button onClick={()=>{BridgeToken(props.provider,props.coin,qty)}} style={!can?{backgroundColor:isbridging?'gray':'red',color:'white'}:{}} disabled={!can} variant="contained">{
            isbridging?'approving..':(can?'Approve':'Insufficient funds')
            }</Button>
        </FormControl>
        {pending?<Typography style={{color:'red',fontSize:'10px'}}><i ref={countUpRef}></i><i> reward PMINT pending</i></Typography>:''}
    </Box>
    )
}