import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import Link2 from '@mui/material/Link';
const pages = ['bridge', 'swap', 'liquidity','stake','lend','pools'];

export default function NavBar(props) {
  return (
    <Box sx={{ flexGrow: 1,zIndex:1301,position:'relative' }}>
      <AppBar position="static">
        <Toolbar>
            <Button component={Link2} href='https://mypeppermint.me' to={''}>
              <Typography
                variant="h5"
                noWrap
                component="div"
                sx={{ mr: 2,fontWeight:700}}
                className="striped1"
              >
                Peppermint
            </Typography>
            </Button>
          
            
          {pages.map((page) => (
                <Button key={page} component={Link} to={'/'+page} color={"inherit"} variant={(page=="lend")?"outlined":null}>
                    {page}
                </Button>
            ))}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                
            </Typography>
          <Link2 target="_blank" href='https://discord.gg/MUPVnQbDsV'>
            <img src="/discord.svg" alt="discord" style={{marginRight:'20px',width:'auto',height:'30px',color:'white'}}/>
          </Link2>
          
          {props.children}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
