import React from "react";
import {
  Dialog,
  Grid,
  IconButton,
  Typography,
  Container
} from "@mui/material";

import { makeStyles, withStyles } from "@mui/styles";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import NetworkButton from './NetworkButton'

const styles = (theme) => ({
  dialogContainer: {
    borderRadius: theme.spacing(2),
  },
});

const useStyles = makeStyles(styles);

export default function WrongNetwork(props) {

  const classes = useStyles();
  const {open} = props;
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      
      classes={{ paper: classes.dialogContainer }}
    >
      <MuiDialogTitle textAlign="center">Unsupported Network</MuiDialogTitle>
      <Container>
        <Typography textAlign="center" color="#75818D" style={{ marginBottom: 23 }}>
            Please connect to <NetworkButton network={'mintme'} chainId={window.constants.chainIds['mintme']} provider={window.provider}/>{' '}
        </Typography>
      </Container>
      
    </Dialog>
  );
}
