import React, { useEffect } from "react";
import {
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles' 
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import { useSnackbar } from "notistack";
import LoopIcon from "@mui/icons-material/Loop";
import {
  getAccount,
  getFactory,
  getProvider,
  getRouter,
  getSigner,
  getNetwork,
  getAmountOut,
  getBalanceAndSymbol,
  getWeth,
  swapTokens,
  getReserves,
} from "../ethereumFunctions";
import CoinField from "./CoinField";
import CoinDialog from "./CoinDialog";
import LoadingButton from "../components/LoadingButton";
import WrongNetwork from "../components/wrongNetwork";
import COINS from "../constants/coins";
import * as chains from "../constants/chains";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Cookies from 'js-cookie'
import { createChart } from 'lightweight-charts';

const fakeData = [
	{ time: "2018-03-28", value: 21.00 },
{ time: "2018-03-29", value: 20.80 },
{ time: "2018-03-30", value: 19.40 },
{ time: "2018-04-02", value: 18.75 },
{ time: "2018-04-03", value: 18.75 },
{ time: "2018-04-04", value: 18.95 },
{ time: "2018-04-05", value: 16.95 },
{ time: "2018-04-06", value: 17.70 },
{ time: "2018-04-09", value: 31.00 },
{ time: "2018-04-10", value: 30.20 },
{ time: "2018-04-11", value: 31.50 },
{ time: "2018-04-12", value: 27.95 },
{ time: "2018-04-13", value: 30.15 },
{ time: "2018-04-16", value: 29.60 },
{ time: "2018-04-17", value: 27.70 },
{ time: "2018-04-18", value: 21.45 },
{ time: "2018-04-19", value: 24.05 },
{ time: "2018-04-20", value: 25.60 },
{ time: "2018-04-23", value: 26.50 },
{ time: "2018-04-24", value: 28.40 },
{ time: "2018-04-25", value: 30.55 },
{ time: "2018-04-26", value: 29.40 },
{ time: "2018-04-27", value: 30.70 },
{ time: "2018-04-30", value: 31.00 },
{ time: "2018-05-02", value: 27.70 },
{ time: "2018-05-03", value: 30.80 },
{ time: "2018-05-04", value: 33.35 },
{ time: "2018-05-07", value: 33.10 },
{ time: "2018-05-08", value: 34.60 },
{ time: "2018-05-10", value: 35.20 },
{ time: "2018-05-11", value: 37.50 },
{ time: "2018-05-14", value: 38.85 },
{ time: "2018-05-15", value: 37.00 },
{ time: "2018-05-16", value: 37.05 },
{ time: "2018-05-17", value: 37.05 },
{ time: "2018-05-18", value: 38.25 },
{ time: "2018-05-21", value: 38.80 },
{ time: "2018-05-22", value: 40.00 },
{ time: "2018-05-23", value: 42.45 },
{ time: "2018-05-24", value: 42.30 },
{ time: "2018-05-25", value: 42.80 },
{ time: "2018-05-28", value: 43.45 },
{ time: "2018-05-29", value: 43.15 },
{ time: "2018-05-30", value: 35.15 },
{ time: "2018-05-31", value: 34.20 },
{ time: "2018-06-01", value: 35.35 },
{ time: "2018-06-04", value: 37.90 },
{ time: "2018-06-05", value: 35.75 },
{ time: "2018-06-06", value: 33.70 },
{ time: "2018-06-07", value: 30.00 },
{ time: "2018-06-08", value: 31.10 },
{ time: "2018-06-11", value: 32.30 },
{ time: "2018-06-13", value: 30.95 },
{ time: "2018-06-14", value: 31.45 },
{ time: "2018-06-15", value: 34.50 },
{ time: "2018-06-18", value: 35.35 },
{ time: "2018-06-19", value: 37.00 },
{ time: "2018-06-20", value: 34.00 },
{ time: "2018-06-21", value: 34.45 },
{ time: "2018-06-22", value: 34.45 },
{ time: "2018-06-25", value: 34.25 },
{ time: "2018-06-26", value: 34.35 },
{ time: "2018-06-27", value: 33.85 },
{ time: "2018-06-28", value: 35.20 },
{ time: "2018-06-29", value: 35.20 },
{ time: "2018-07-02", value: 34.85 },
{ time: "2018-07-03", value: 31.95 },
{ time: "2018-07-04", value: 35.00 },
{ time: "2018-07-05", value: 45.80 },
{ time: "2018-07-06", value: 45.45 },
{ time: "2018-07-09", value: 46.70 },
{ time: "2018-07-10", value: 48.45 },
{ time: "2018-07-11", value: 50.70 },
{ time: "2018-07-12", value: 50.20 },
{ time: "2018-07-13", value: 51.75 },
{ time: "2018-07-16", value: 52.00 },
{ time: "2018-07-17", value: 50.75 },
{ time: "2018-07-18", value: 52.00 },
{ time: "2018-07-19", value: 54.00 },
{ time: "2018-07-20", value: 53.55 },
{ time: "2018-07-23", value: 51.20 },
{ time: "2018-07-24", value: 52.85 },
{ time: "2018-07-25", value: 53.70 },
{ time: "2018-07-26", value: 52.30 },
{ time: "2018-07-27", value: 52.80 },
{ time: "2018-07-30", value: 53.30 },
{ time: "2018-07-31", value: 52.05 },
{ time: "2018-08-01", value: 54.00 },
{ time: "2018-08-02", value: 59.00 },
{ time: "2018-08-03", value: 56.90 },
{ time: "2018-08-06", value: 60.70 },
{ time: "2018-08-07", value: 60.75 },
{ time: "2018-08-08", value: 63.15 },
{ time: "2018-08-09", value: 65.30 },
{ time: "2018-08-10", value: 70.00 },
{ time: "2018-08-13", value: 69.25 },
{ time: "2018-08-14", value: 67.75 },
{ time: "2018-08-15", value: 67.60 },
{ time: "2018-08-16", value: 64.50 },
{ time: "2018-08-17", value: 66.00 },
{ time: "2018-08-20", value: 66.05 },
{ time: "2018-08-21", value: 66.65 },
{ time: "2018-08-22", value: 66.40 },
{ time: "2018-08-23", value: 69.35 },
{ time: "2018-08-24", value: 70.55 },
{ time: "2018-08-27", value: 68.80 },
{ time: "2018-08-28", value: 68.45 },
{ time: "2018-08-29", value: 63.20 },
{ time: "2018-08-30", value: 59.50 },
{ time: "2018-08-31", value: 59.50 },
{ time: "2018-09-03", value: 60.45 },
{ time: "2018-09-04", value: 62.25 },
{ time: "2018-09-05", value: 63.50 },
{ time: "2018-09-06", value: 66.90 },
{ time: "2018-09-07", value: 66.45 },
{ time: "2018-09-10", value: 68.50 },
{ time: "2018-09-11", value: 69.90 },
{ time: "2018-09-12", value: 67.80 },
{ time: "2018-09-13", value: 67.90 },
{ time: "2018-09-14", value: 69.25 },
{ time: "2018-09-17", value: 68.95 },
{ time: "2018-09-18", value: 65.85 },
{ time: "2018-09-19", value: 63.60 },
{ time: "2018-09-20", value: 64.00 },
{ time: "2018-09-21", value: 64.00 },
{ time: "2018-09-24", value: 66.05 },
{ time: "2018-09-25", value: 68.35 },
{ time: "2018-09-26", value: 68.30 },
{ time: "2018-09-27", value: 67.95 },
{ time: "2018-09-28", value: 68.45 },
{ time: "2018-10-01", value: 68.80 },
{ time: "2018-10-02", value: 68.60 },
{ time: "2018-10-03", value: 67.90 },
{ time: "2018-10-04", value: 68.60 },
{ time: "2018-10-05", value: 70.35 },
{ time: "2018-10-08", value: 72.35 },
{ time: "2018-10-09", value: 72.90 },
{ time: "2018-10-10", value: 72.85 },
{ time: "2018-10-11", value: 74.10 },
{ time: "2018-10-12", value: 73.00 },
{ time: "2018-10-15", value: 74.85 },
{ time: "2018-10-16", value: 76.00 },
{ time: "2018-10-17", value: 77.00 },
{ time: "2018-10-18", value: 79.00 },
{ time: "2018-10-19", value: 79.50 },
{ time: "2018-10-22", value: 82.60 },
{ time: "2018-10-23", value: 82.70 },
{ time: "2018-10-24", value: 82.10 },
{ time: "2018-10-25", value: 83.15 },
{ time: "2018-10-26", value: 83.40 },
{ time: "2018-10-29", value: 80.95 },
{ time: "2018-10-30", value: 76.75 },
{ time: "2018-10-31", value: 77.75 },
{ time: "2018-11-01", value: 78.12 },
{ time: "2018-11-02", value: 73.22 },
{ time: "2018-11-06", value: 72.60 },
{ time: "2018-11-07", value: 74.40 },
{ time: "2018-11-08", value: 76.50 },
{ time: "2018-11-09", value: 79.86 },
{ time: "2018-11-12", value: 78.10 },
{ time: "2018-11-13", value: 77.60 },
{ time: "2018-11-14", value: 71.70 },
{ time: "2018-11-15", value: 72.26 },
{ time: "2018-11-16", value: 73.80 },
{ time: "2018-11-19", value: 76.28 },
{ time: "2018-11-20", value: 72.80 },
{ time: "2018-11-21", value: 66.20 },
{ time: "2018-11-22", value: 65.10 },
{ time: "2018-11-23", value: 61.26 },
{ time: "2018-11-26", value: 64.10 },
{ time: "2018-11-27", value: 61.72 },
{ time: "2018-11-28", value: 61.40 },
{ time: "2018-11-29", value: 61.86 },
{ time: "2018-11-30", value: 60.60 },
{ time: "2018-12-03", value: 63.16 },
{ time: "2018-12-04", value: 68.30 },
{ time: "2018-12-05", value: 67.20 },
{ time: "2018-12-06", value: 68.56 },
{ time: "2018-12-07", value: 71.30 },
{ time: "2018-12-10", value: 73.98 },
{ time: "2018-12-11", value: 72.28 },
{ time: "2018-12-12", value: 73.20 },
{ time: "2018-12-13", value: 73.00 },
{ time: "2018-12-14", value: 72.90 },
{ time: "2018-12-17", value: 73.96 },
{ time: "2018-12-18", value: 73.40 },
{ time: "2018-12-19", value: 73.00 },
{ time: "2018-12-20", value: 72.98 },
{ time: "2018-12-21", value: 72.80 },
{ time: "2018-12-24", value: 72.90 },
{ time: "2018-12-25", value: 74.20 },
{ time: "2018-12-26", value: 73.98 },
{ time: "2018-12-27", value: 74.50 },
{ time: "2018-12-28", value: 74.00 },
{ time: "2019-01-03", value: 73.50 },
{ time: "2019-01-04", value: 73.90 },
{ time: "2019-01-08", value: 73.90 },
{ time: "2019-01-09", value: 72.94 },
{ time: "2019-01-10", value: 69.86 },
{ time: "2019-01-11", value: 70.34 },
{ time: "2019-01-14", value: 68.78 },
{ time: "2019-01-15", value: 68.02 },
{ time: "2019-01-16", value: 66.60 },
{ time: "2019-01-17", value: 65.94 },
{ time: "2019-01-18", value: 68.00 },
{ time: "2019-01-21", value: 69.20 },
{ time: "2019-01-22", value: 69.76 },
{ time: "2019-01-23", value: 69.60 },
{ time: "2019-01-24", value: 69.62 },
{ time: "2019-01-25", value: 69.30 },
{ time: "2019-01-28", value: 69.20 },
{ time: "2019-01-29", value: 68.90 },
{ time: "2019-01-30", value: 66.40 },
{ time: "2019-01-31", value: 67.08 },
{ time: "2019-02-01", value: 69.78 },
{ time: "2019-02-04", value: 72.56 },
{ time: "2019-02-05", value: 72.74 },
{ time: "2019-02-06", value: 73.00 },
{ time: "2019-02-07", value: 73.38 },
{ time: "2019-02-08", value: 73.10 },
{ time: "2019-02-11", value: 73.22 },
{ time: "2019-02-12", value: 72.30 },
{ time: "2019-02-13", value: 74.86 },
{ time: "2019-02-14", value: 73.64 },
{ time: "2019-02-15", value: 73.38 },
{ time: "2019-02-18", value: 74.26 },
{ time: "2019-02-19", value: 75.00 },
{ time: "2019-02-20", value: 74.96 },
{ time: "2019-02-21", value: 75.00 },
{ time: "2019-02-22", value: 74.88 },
{ time: "2019-02-25", value: 74.96 },
{ time: "2019-02-26", value: 76.02 },
{ time: "2019-02-27", value: 77.30 },
{ time: "2019-02-28", value: 77.90 },
{ time: "2019-03-01", value: 78.24 },
{ time: "2019-03-04", value: 76.64 },
{ time: "2019-03-05", value: 78.74 },
{ time: "2019-03-06", value: 76.88 },
{ time: "2019-03-07", value: 75.32 },
{ time: "2019-03-11", value: 72.90 },
{ time: "2019-03-12", value: 74.78 },
{ time: "2019-03-13", value: 74.50 },
{ time: "2019-03-14", value: 75.34 },
{ time: "2019-03-15", value: 74.92 },
{ time: "2019-03-18", value: 75.08 },
{ time: "2019-03-19", value: 75.54 },
{ time: "2019-03-20", value: 76.78 },
{ time: "2019-03-21", value: 77.70 },
{ time: "2019-03-22", value: 77.34 },
{ time: "2019-03-25", value: 78.00 },
{ time: "2019-03-26", value: 77.98 },
{ time: "2019-03-27", value: 78.90 },
{ time: "2019-03-28", value: 78.30 },
{ time: "2019-03-29", value: 78.70 },
{ time: "2019-04-01", value: 77.22 },
{ time: "2019-04-02", value: 76.64 },
{ time: "2019-04-03", value: 76.50 },
{ time: "2019-04-04", value: 76.64 },
{ time: "2019-04-05", value: 75.46 },
{ time: "2019-04-08", value: 76.42 },
{ time: "2019-04-09", value: 77.76 },
{ time: "2019-04-10", value: 77.68 },
{ time: "2019-04-11", value: 76.60 },
{ time: "2019-04-12", value: 76.78 },
{ time: "2019-04-15", value: 76.28 },
{ time: "2019-04-16", value: 75.88 },
{ time: "2019-04-17", value: 76.38 },
{ time: "2019-04-18", value: 77.00 },
{ time: "2019-04-19", value: 77.40 },
{ time: "2019-04-22", value: 77.40 },
{ time: "2019-04-23", value: 78.20 },
{ time: "2019-04-24", value: 78.68 },
{ time: "2019-04-25", value: 78.66 },
{ time: "2019-04-26", value: 77.88 },
{ time: "2019-04-29", value: 78.02 },
{ time: "2019-04-30", value: 78.68 },
{ time: "2019-05-02", value: 78.14 },
{ time: "2019-05-03", value: 78.30 },
{ time: "2019-05-06", value: 80.06 },
{ time: "2019-05-07", value: 80.50 },
{ time: "2019-05-08", value: 80.76 },
{ time: "2019-05-10", value: 82.10 },
{ time: "2019-05-13", value: 83.72 },
{ time: "2019-05-14", value: 83.55 },
{ time: "2019-05-15", value: 84.92 },
{ time: "2019-05-16", value: 83.32 },
{ time: "2019-05-17", value: 83.04 },
{ time: "2019-05-20", value: 83.92 },
{ time: "2019-05-21", value: 84.24 },
{ time: "2019-05-22", value: 84.00 },
{ time: "2019-05-23", value: 84.26 },
{ time: "2019-05-24", value: 84.00 },
{ time: "2019-05-27", value: 83.80 },
{ time: "2019-05-28", value: 84.32 },
{ time: "2019-05-29", value: 83.88 },
{ time: "2019-05-30", value: 84.58 },
{ time: "2019-05-31", value: 81.20 },
{ time: "2019-06-03", value: 84.35 },
{ time: "2019-06-04", value: 85.66 },
{ time: "2019-06-05", value: 86.51 },
];
const styles = (theme) => ({
  paperContainer: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  switchButton: {
    zIndex: 1,
    marginBottom:'-16px',
    // margin: "-16px",
    padding: theme.spacing(0.5),
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  hr: {
    width: "100%",
  },
  balance: {
    padding: theme.spacing(1),
    overflow: "wrap",
    fontSize: "12px",
    textAlign: "center",
  },
  footer: {
    marginTop: "285px",
  },
});
const useStyles = makeStyles(styles);
function ChartError(props){
  return (
    <div style={{position: 'absolute',top: '50%',left: '50%',zIndex:5,transform: 'translate(-50%, -50%)'}}>
      <Typography variant="h4" >{props.msg}</Typography>
    </div>
  )
}
function Charts(props){
  const chartRef = React.createRef();
  const [err,setErr] = React.useState()
  const classes = useStyles();
  useEffect(()=>{
    console.log('CHART CHANGING.... ')
    const div = chartRef.current;
    const chart = createChart(div, { 
      width: div.clientWidth, 
      height: div.clientHeight,
      leftPriceScale: {
        scaleMargins: {
          top: 0.2,
          bottom: 0.2,
        },
        visible: true,
        borderVisible: false,
      },
      rightPriceScale: {
        visible: false,
      },
      timeScale: {
        borderVisible: false,
      },
      grid: {
        horzLines: {
          color: '#eee',
        },
        vertLines: {
          color: '#ffffff',
        },
      },
      crosshair: {
          horzLine: {
            visible: false,
            labelVisible: false
          },
          vertLine: {
            visible: true,
            style: 0,
            width: 2,
            color: 'rgba(32, 38, 46, 0.1)',
            labelVisible: false,
          }
      } });
    var series = chart.addAreaSeries({	
      topColor: 'rgba(0, 120, 255, 0.2)',	
      bottomColor: 'rgba(0, 120, 255, 0.0)',
      lineColor: 'rgba(0, 120, 255, 1)',
      lineWidth: 3,
      priceFormat: {
        minMove:0.0001,
        precision:4
      }
    });
    if(props.coin1.address&&props.coin2.address){
      fetch(window.chartHost+'/api/priceHistory?coin1='+props.coin1.address+'&coin2='+props.coin2.address)
      .then(d=>d.json())
      .then(function(data){
        const history = data.history;
        let chartData = history;
        // console.log('CD: ',chartData);
        // for(var date in history){
        //   chartData.push({ time: date-0, value: history[date] })
        // }
        if(chartData.length===0){
          setErr('No Data')
        }else{
          series.setData(chartData);
        }
      }).catch(function() {
        console.log('SERVER NOT ONLINE')
          setErr('No Data')
      });
    }else{
      setErr('No Data')
      //series.setData(fakeData);
     // series.setData(fakeData);
    }
    
    
      // 
    
    return ()=>{
      setErr(null)
      if(div)
        div.innerHTML = '';
    };
  },[props.coin1.address,props.coin2.address])
  return (
    <Container maxWidth="sm" sx={{height:'100%'}}>
      <Box fullWidth sx={{height:'100%'}}>
        <Card sx={{height:'80%'}}>
          <CardContent sx={{height:'100%',textAlign:'center'}}>
            <Typography variant="h5" className={classes.title}>
              {(props.coin1.symbol||'--')+"/"+(props.coin2.symbol||'--')}
            </Typography>
            <div style={{width:'100%',height:'256px',position:'relative',textAlign:'center'}}>
              {err?<ChartError msg={err} />:<div/>}
              <div style={{width:'100%',height:'256px' }} id="chartDiv" ref={chartRef}></div>
            </div>
          </CardContent>
        </Card>
        
      </Box>
    </Container>
  )
}

function CoinSwapper(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  

  // Stores information for the Autonity Network

  const [provider, setProvider] = React.useState(getProvider());
  const [signer, setSigner] = React.useState(getSigner(provider));

  // The following are populated in a react hook
  const [account, setAccount] = React.useState(undefined);
  const [chainId, setChainId] = React.useState(undefined);
  const [router, setRouter] = React.useState(undefined);
  const [weth, setWeth] = React.useState(undefined);
  const [factory, setFactory] = React.useState(undefined);

  // Stores a record of whether their respective dialog window is open
  const [dialog1Open, setDialog1Open] = React.useState(false);
  const [dialog2Open, setDialog2Open] = React.useState(false);
  const [wrongNetworkOpen, setwrongNetworkOpen] = React.useState(false);
  

  // Stores data about their respective coin
  const [coin1, setCoin1] = React.useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });
  const [coin2, setCoin2] = React.useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });

  const [coins, setCoins] = React.useState([]);

  // Stores the current reserves in the liquidity pool between coin1 and coin2
  const [reserves, setReserves] = React.useState(["0.0", "0.0"]);

  // Stores the current value of their respective text box
  const [field1Value, setField1Value] = React.useState("");
  const [field2Value, setField2Value] = React.useState("");

  // Controls the loading button
  const [loading, setLoading] = React.useState(false);

  

  // Switches the top and bottom coins, this is called when users hit the swap button or select the opposite
  // token in the dialog (e.g. if coin1 is TokenA and the user selects TokenB when choosing coin2)
  const switchFields = () => {
    setCoin1(coin2);
    setCoin2(coin1);
    setField1Value(field2Value);
    setReserves(reserves.reverse());
  };

  // These functions take an HTML event, pull the data out and puts it into a state variable.
  const handleChange = {
    field1: (e) => {
      setField1Value(e.target.value);
    },
  };

  // Turns the account's balance into something nice and readable
  const formatBalance = (balance, symbol) => {
    if (balance && symbol)
      return parseFloat(balance).toPrecision(8) + " " + symbol;
    else return "0.0";
  };

  // Turns the coin's reserves into something nice and readable
  const formatReserve = (reserve, symbol) => {
    if (reserve && symbol) return reserve + " " + symbol;
    else return "0.0";
  };
  
  // Determines whether the button should be enabled or not
  const isButtonEnabled = () => {

    // If both coins have been selected, and a valid float has been entered which is less than the user's balance, then return true
    const parsedInput1 = parseFloat(field1Value);
    const parsedInput2 = parseFloat(field2Value);
    return (
      coin1.address &&
      coin2.address &&
      !isNaN(parsedInput1) &&
      !isNaN(parsedInput2) &&
      0 < parsedInput1 &&
      parsedInput1 <= coin1.balance
    );
  };
  // let lastCoins = [coins[0].address,coins[1].address];
  //     // if(Cookies.get('token1')){
  //     //   lastCoins[0] = Cookies.get('token1')
  //     // }
  //     // if(Cookies.get('token2')){
  //     //   lastCoins[1] = Cookies.get('token2')
  //     // }
  //     // console.log(lastCoins)
  //     //Cookies
  //     onToken1Selected(lastCoins[0]);
  //     onToken2Selected(lastCoins[1])

  // Called when the dialog window for coin1 exits

  const coinAddresses = {};
  for(var i=0;i<coins.length;i++){
    coinAddresses[coins[i].address] = i;
  }

  const onToken1Selected = (address) => {

    // if(coinAddresses[address]!==undefined){
    //   const coinIndex = coinAddresses[address];
    //   if(coinIndex>2){
    //     console.log('PMINT DEFAULT MARKET')
    //     onToken2Selected(coins[1].address)
    //   }
    //   console.log('COIN IN LIST')
    // }
    
    // Close the dialog window
    setDialog1Open(false);

    // If the user inputs the same token, we want to switch the data in the fields
    if (address === coin2.address) {
      switchFields();
    }
    // We only update the values if the user provides a token
    else if (address) {
      // Getting some token data is async, so we need to wait for the data to return, hence the promise
      getBalanceAndSymbol(account, address, provider, signer, weth.address, coins).then((data) => {
        setCoin1({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  // Called when the dialog window for coin2 exits
  const onToken2Selected = (address) => {

    // if(coinAddresses[address]!==undefined){
    //   const coinIndex = coinAddresses[address];
    //   if(coinIndex>2){
    //     console.log('PMINT DEFAULT MARKET')
    //     onToken1Selected(coins[1].address)
    //   }
    //   console.log('COIN IN LIST')
    // }
    // Close the dialog window
    setDialog2Open(false);

    // If the user inputs the same token, we want to switch the data in the fields
    if (address === coin1.address) {
      switchFields();
    }
    // We only update the values if the user provides a token
    else if (address) {
      // Getting some token data is async, so we need to wait for the data to return, hence the promise
      getBalanceAndSymbol(account, address, provider, signer, weth.address, coins).then((data) => {
        
        setCoin2({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  // Calls the swapTokens Ethereum function to make the swap, then resets nessicary state variables
  const swap = () => {
    console.log("Attempting to swap tokens...");
    setLoading(true);

    swapTokens(
      coin1.address,
      coin2.address,
      field1Value,
      router,
      account,
      signer
    )
      .then(() => {
        setLoading(false);

        // If the transaction was successful, we clear to input to make sure the user doesn't accidental redo the transfer
        setField1Value("");
        enqueueSnackbar("Transaction Successful", { variant: "success" });
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar("Transaction Failed (" + e.message + ")", {
          variant: "error",
          autoHideDuration: 10000,
        });
      });
  };
  
  // The lambdas within these useEffects will be called when a particular dependency is updated. These dependencies
  // are defined in the array of variables passed to the function after the lambda expression. If there are no dependencies
  // the lambda will only ever be called when the component mounts. These are very useful for calculating new values
  // after a particular state change, for example, calculating the new exchange rate whenever the addresses
  // of the two coins change.

  // This hook is called when either of the state variables `coin1.address` or `coin2.address` change.
  // This means that when the user selects a different coin to convert between, or the coins are swapped,
  // the new reserves will be calculated.
  useEffect(() => {
    console.log(
      "Trying to get Reserves between:\n" + coin1.address + "\n" + coin2.address
    );

    if (coin1.address && coin2.address) {
      getReserves(coin1.address, coin2.address, factory, signer, account).then(
        (data) => setReserves(data)
      );
    }
  }, [coin1.address, coin2.address, account, factory, router, signer]);

  // useEffect(() =>{
  //   if(coin1.address){
  //     Cookies.set('token1',coin1.address)
  //   }else{
  //     Cookies.remove('token1')
  //   }
  //   if(coin2.address){
  //     Cookies.set('token2',coin2.address)
  //   }else{
  //     Cookies.remove('token2')
  //   }
  // },[coin1.address,coin2.address])

  useEffect(() => {
    try{
      let lastCoins = [coins[0].address,coins[1].address];
      // if(Cookies.get('token1')){
      //   lastCoins[0] = Cookies.get('token1')
      // }
      // if(Cookies.get('token2')){
      //   lastCoins[1] = Cookies.get('token2')
      // }
      // console.log(lastCoins)
      //Cookies
      onToken1Selected(lastCoins[0]);
      onToken2Selected(lastCoins[1])
    }catch(e){
      console.warn('eth not initted')
    }
  }, [coins,weth]);
  // This hook is called when either of the state variables `field1Value` `coin1.address` or `coin2.address` change.
  // It attempts to calculate and set the state variable `field2Value`
  // This means that if the user types a new value into the conversion box or the conversion rate changes,
  // the value in the output box will change.
  useEffect(() => {
    if (isNaN(parseFloat(field1Value))) {
      setField2Value("");
    } else if (parseFloat(field1Value) && coin1.address && coin2.address) {
      getAmountOut(coin1.address, coin2.address, field1Value, router, signer).then(
        (amount) => setField2Value(amount.toFixed(7))
      ).catch(e => {
        console.log(e);
        setField2Value("NA");
      })
    } else {
      setField2Value("");
    }
  }, [field1Value, coin1.address, coin2.address]);

  // This hook creates a timeout that will run every ~10 seconds, it's role is to check if the user's balance has
  // updated has changed. This allows them to see when a transaction completes by looking at the balance output.
  useEffect(() => {
    const coinTimeout = setTimeout(() => {
      console.log("Checking balances...");

      if (coin1.address && coin2.address && account) {
        getReserves(
          coin1.address,
          coin2.address,
          factory,
          signer,
          account
        ).then((data) => setReserves(data));
      }

      if (coin1.address && account &&!wrongNetworkOpen) {
        getBalanceAndSymbol(account, coin1.address, provider, signer, weth.address, coins).then(
          (data) => {
            setCoin1({
              ...coin1,
              balance: data.balance,
            });
          }
        );
      }
      if (coin2.address && account &&!wrongNetworkOpen) {
        getBalanceAndSymbol(account, coin2.address, provider, signer, weth.address, coins).then(
          (data) => {
            setCoin2({
              ...coin2,
              balance: data.balance,
            });
          }
        );
      }
    }, 10000);

    return () => clearTimeout(coinTimeout);
  });

  // This hook will run when the component first mounts, it can be useful to put logic to populate variables here
  useEffect(() => {
    
    getAccount().then((account) => {
      setAccount(account);
    });

    async function Network() {
      const chainId = await getNetwork(provider).then((chainId) => {
        setChainId(chainId);
        return chainId;
      });
      console.log('chainID',chainId,chains.networks)

      if (chains.networks.includes(chainId)){
        setwrongNetworkOpen(false);
        console.log('chainID: ', chainId);
        // Get the router using the chainID
        const router = await getRouter (chains.routerAddress.get(chainId), signer)
        setRouter(router);
        // Get Weth address from router
        await router.WETH().then((wethAddress) => {
          console.log('Weth: ', wethAddress);
          setWeth(getWeth (wethAddress, signer));
          // Set the value of the weth address in the default coins array
          const coins = COINS.get(chainId);
          coins[0].address = wethAddress;
          setCoins(coins);
          console.log(coins,wethAddress,coins[1].address);
          
          
        });
        // Get the factory address from the router
        await router.factory().then((factory_address) => {
          setFactory(getFactory (factory_address, signer));
        })
      } else {
        console.log('Wrong network mate.');
        setwrongNetworkOpen(true);
      }
    }

    Network()

  }, []);
  
  return (
    <Container fixed>
      {/* Dialog Windows */}
      <CoinDialog
        open={dialog1Open}
        onClose={onToken1Selected}
        coins={coins}
        signer={signer}
      />
      <CoinDialog
        open={dialog2Open}
        onClose={onToken2Selected}
        coins={coins}
        signer={signer}
      />
      <WrongNetwork
        open={wrongNetworkOpen}
        />

      {/* Coin Swapper */}
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Charts coin1={coin1} coin2={coin2}></Charts>
        </Grid>
        <Grid item xs={5}>
          <Card>
            <CardContent>
              <Box fullWidth sx={{ width: '100%' }}>
                <Typography variant="h5" className={classes.title}>
                  Swap Coins
                </Typography>
            
                <Grid container direction="column" alignItems="center" spacing={2}>
                  <Grid item xs={12} className={classes.fullWidth}>
                    <CoinField
                      label="From"
                      activeField={true}
                      value={field1Value}
                      onClick={() => setDialog1Open(true)}
                      onChange={handleChange.field1}
                      symbol={coin1.symbol !== undefined ? coin1.symbol : "Select"}
                    />
                  </Grid>
                  <IconButton onClick={switchFields} className={classes.switchButton}>
                    <SwapVerticalCircleIcon />
                  </IconButton>

                  <Grid item xs={12} className={classes.fullWidth}>
                    <CoinField
                      label="To"
                      activeField={false}
                      value={field2Value}
                      onClick={() => setDialog2Open(true)}
                      symbol={coin2.symbol !== undefined ? coin2.symbol : "Select"}
                    />
                  </Grid>

                  <hr className={classes.hr} />

                  {/* Balance Display */}
                  <Typography fontSize="14px" variant="h6">Your Balances</Typography>
                  <Grid container  direction="row" justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.balance}>
                        {formatBalance(coin1.balance, coin1.symbol)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.balance}>
                        {formatBalance(coin2.balance, coin2.symbol)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <hr className={classes.hr} />

                  {/* Reserves Display */}
                  <Typography fontSize="14px" variant="h6">Reserves</Typography>
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.balance}>
                        {formatReserve(reserves[0], coin1.symbol)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.balance}>
                        {formatReserve(reserves[1], coin2.symbol)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <hr className={classes.hr} />
                  <Typography variant="body2" element={'div'} sx={{color:'red',textAlign:'center'}}>
                    <b>
                    {reserves[0]=="0"?'No Liquidity! If you have these coins, please provide some by clicking "liquidity"':''}
                    </b>
                  </Typography>
                  <LoadingButton
                    loading={loading}
                    valid={isButtonEnabled()}
                    success={false}
                    fail={false}
                    onClick={swap}
                  >
                    <LoopIcon />
                    Swap
                  </LoadingButton>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
{/* 
      <Grid
        container
        className={classes.footer}
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
      >
        <p>
          Clearmatics Autonity Uniswap | Get AUT for use in the bakerloo testnet{" "}
          <a href="https://faucet.bakerloo.autonity.network/">here</a>
        </p>
      </Grid> */}
    </Container>
  );
}

export default CoinSwapper;
