import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import BridgeSection from './BridgeSection';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);

  if(props.provider){
      (async function(){
        const { chainId } = await props.provider.getNetwork()
        console.log(chainId);
      })()
        
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
    <Card>
        <CardContent>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Bridge xDAI to pUSD" {...a11yProps(0)} />
                  <Tab label="Bridge pUSD to xDAI" {...a11yProps(1)} />
                  </Tabs>
              </Box>
              <TabPanel component="div" value={value} index={0}>
                  {(value===0)?<BridgeSection provider={props.provider} network={'xdai'}/>:'Error'}
              </TabPanel>
              <TabPanel component="div" value={value} index={1}>
                  {(value===1)?<BridgeSection provider={props.provider} network={'mintme'}/>:'Error'}
              </TabPanel>
            </Box>
            <Typography sx={{textAlign:'center'}}>
              <h3 style={{margin:'5px'}}>xDAI can be purchased directly with fiat <Link target="_blank" href="https://ramp.network/buy/?defaultAsset=XDAI">here</Link></h3>
              To obtain xDAI with crypto, please consult <Link target="_blank" href="https://medium.com/@mypeppermintdev/how-to-use-the-xdai-to-pusd-bridge-8b3e85b90ccc">this guide</Link><br/><br/>
              <b>Bridging may take a while, please be patient. <br/> If you do not get a confirmation or error in 15 min please contact support</b>
            </Typography>
        </CardContent>
    </Card>
    </div>
  );
}
