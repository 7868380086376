import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function HelpButton(props){
    return (
        <Tooltip  title={props.text}>
            <IconButton size="small" >
                <HelpOutlineIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    )
}