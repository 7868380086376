import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ERCAbi from '../constants/abis/IERC20.json'
import BarAbi from '../constants/abis/SushiBar.json'
import MakerAbi from '../constants/abis/SushiMaker.json'
import FlashInAbi from '../constants/abis/FlashIn.json'
import WETHAbi from '../constants/abis/IWETH.json'

import addresses from '../constants/swapAddresses.json'
import deployAddresses from '../constants/swapAddresses24734.json'
import { ethers, utils } from 'ethers';
import WrongNetwork from './wrongNetwork';

import StakeSection from './FlashStakeSection';



export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const estApy = 137;
function CoinsHeld(props){
  return (
    <Grid item xs={6}>
      <Typography><b>{props.balance}</b> </Typography>
      <Typography>{props.coin}</Typography>
      
    </Grid>
  )
}
let bar,pmint,adr,vault,enter,weth;
export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);
  const [exchange,setExchange] = React.useState(1);
  const [balances,setBalances] = React.useState(['--','--'])
  const [wrongNetworkOpen, setwrongNetworkOpen] = React.useState(false);

  if(props.provider){
      (async function(){
        const { chainId } = await props.provider.getNetwork()
        if(chainId!==window.constants.chainIds['mintme']){
          setwrongNetworkOpen(true);
        }
      })()
        
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function estApy(exchange){
    var exchange = exchange/1.1113445570518802885;
    var start = 1654195219119;
              //1643133177510;
    var timePassed = (new Date()-start)/(1000*60*60*24*365);
    var returns = Math.pow(Math.E,Math.log(exchange)*(1/timePassed));
    console.log(returns,timePassed)
    if(timePassed<0.04||returns===1){
      return 3.68;
    }else{
      return ((returns-1)*200).toFixed(2);
    }
    
  }
  React.useEffect(() => {
    const interval = setInterval(async () => {
      if(wrongNetworkOpen){
        return;
      }
      if(pmint){
        console.log('updating balances ... ')
        var raw = [await props.provider.getBalance(adr),await bar.balanceOf(adr)];
        raw = raw.map(x=>Number(ethers.utils.formatEther(x)).toFixed(2))
        setBalances(raw)
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  React.useEffect(() => {
    (async function(){
      if(wrongNetworkOpen){
        return;
      }
        try{
        const myAddr = window.TESTING?addresses:deployAddresses;
        const signer = await props.provider.getSigner();
        adr = await signer.getAddress();
        bar = window.vault = new ethers.Contract(myAddr.flashVault,BarAbi,signer);
        pmint = new ethers.Contract(myAddr.weth,ERCAbi.abi,signer);
        enter = new ethers.Contract(myAddr.flashIn,FlashInAbi,signer);
        weth = new ethers.Contract(myAddr.weth,WETHAbi,signer);

        console.log('FLASH VAULT: ',myAddr.flashVault);

        var raw = [await props.provider.getBalance(adr),await bar.balanceOf(adr)];
        raw = raw.map(x=>Number(ethers.utils.formatEther(x)).toFixed(2))
        setBalances(raw)
        // bar =window.bar = new ethers.Contract(myAddr.bar,BarAbi,signer);
        // pmint = new ethers.Contract(myAddr.weth,ERCAbi.abi,signer);
        // var raw = [await pmint.balanceOf(adr),await bar.balanceOf(adr)];
        // raw = raw.map(x=>Number(ethers.utils.formatEther(x)).toFixed(2))
        // setBalances(raw)

        // window.getBuyBacks = async function(){
        //   window.maker = new ethers.Contract(myAddr.maker,MakerAbi,signer);
        //   let eventFilter = window.maker.filters.LogConvert()
        //   let events = await window.maker.queryFilter(eventFilter);
        //   console.log('BUYBACKS: ',events.length)
        //   for(var i=0;i<events.length;i++){
        //     console.log(new Date((await events[i].getBlock()).timestamp*1000).toLocaleString(),'\t',ethers.utils.formatEther(events[i].args.amountSUSHI),'PMINT');
        //   }
        //   console.log(events);
        // }
        


        // console.log('BAR ADDRESS',myAddr.bar);
        const barshares = ethers.utils.formatEther(await bar.totalSupply())-0;
        const barpmint = ethers.utils.formatEther(await pmint.balanceOf(bar.address))-0;
        console.log(barshares,barpmint)

        if(barshares===0){
          setExchange(1);
        }else{
          setExchange(barpmint/barshares)
        }
      } catch (e) {
        console.log('wrong network mate')
      }
      

      
      // bar.balanceOf()

      //console.log('bar balance, ',await bar.totalSupply())
      // console.log('BAR ',bar);
    })();
  },[]);


  return (
    <div>
      <WrongNetwork open={wrongNetworkOpen} />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card>
              <CardContent>
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Lend MINTME" {...a11yProps(0)} />
                        <Tab label="remove" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel component="div" value={value} index={0}>
                        {(value===0)?<StakeSection bar={bar} enter={enter} pmint={pmint} provider={props.provider} coin={'mintme'}/>:'Error'}
                    </TabPanel>
                    <TabPanel component="div" value={value} index={1}>
                        {(value===1)?<StakeSection bar={bar} pmint={pmint} weth={weth} ex={exchange} provider={props.provider} coin={'XMINTME'}/>:'Error'}
                    </TabPanel>
                    <Typography fontSize="14px" color="red" textAlign="center" variant="h6">1 xMINTME = {exchange.toFixed(19)} MINTME</Typography>
                    <Typography fontSize="14px" color="gray" textAlign="center" variant="h6">
                      Read about how to make flash loans with peppermint <a target="_blank" href="https://github.com/PeppermintDev/peppermint-flashloans">here</a>
                    </Typography>
                  </Box>
                  
              </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent  style={{textAlign:'center'}}>
              <Typography color="gray" variant="h5">Current est APY: </Typography>
              <Typography variant="h2"><b>{estApy(exchange)}%</b></Typography>
              
              <Typography color="gray" fontSize="10px" variant="p">
                <i>Please note that staked rewards are not <br/>continuously compounded.</i>
              </Typography>
              <br/>
              
            </CardContent>
          </Card>
          <Card margin="10px">
            <CardContent  style={{textAlign:'center'}}>
              <Grid container spacing={2}>
                <CoinsHeld coin={'MINTME'} balance={balances[0]} />
                <CoinsHeld coin={'xMINTME'} balance={balances[1]} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    
    </div>
  );
}
