import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import styled from "styled-components";
import { Text, TextBold } from './text';
import { ethers, utils } from 'ethers';
import Swal from 'sweetalert2'
import NetworkButton from './NetworkButton'
import LoadingButton from "../components/LoadingButton";
import { CircularProgress } from "@mui/material";
// import AccountCircle from '@mui/icons-material/AccountCircle';
const SwitchNetworkButton = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 7px;
  position: relative;
  bottom: -3px;
  cursor: pointer;
  background-color: #f3e2fd;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background-color: #f2dbff;
  }
`;
window.ethersutils = utils;

const MetamaskIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;
// const NetworkButton = (props) => {

//     const isMetamask = !props.provider || (props.provider.connection.url==='metamask');
//     const name = props.network;
//     const chainId = props.chainId;
//     if (!isMetamask) {
//         return <b>{name}</b>;
//     }
//     async function switchChainId(chainId){
//         console.log('SWITCHING NETWORK')
//         const ethereum = window.ethereum;
//         try {
//             await ethereum.request({
//                 method: 'wallet_switchEthereumChain',
//                 params: [{ chainId: utils.hexValue(Number(chainId)) }],
//             });
//             console.log('requesting')
//         } catch (switchError) {
//             console.log(switchError)
//             // This error code indicates that the chain has not been added to MetaMask.
//             if (switchError.code === 4902) {
//                 try {
//                 await ethereum.request({
//                     method: 'wallet_addEthereumChain',
//                     params: [window.constants.configs[name]],
//                 });
//                 } catch (addError) {
//                 // handle "add" error
//                 }
//             }
//         // handle other "switch" errors
//         }
//     }
    
    
//     return (
//       <SwitchNetworkButton onClick={() => switchChainId(chainId)}>
//         <MetamaskIcon src="/img/metamask.svg" />
//         <TextBold color="#943ACA">{name}</TextBold>
//       </SwitchNetworkButton>
//     );
//   }

function BridgeToken(provider,network,qty,setBridiging){
    return async () =>{
        setBridiging(true);
        const signer = await provider.getSigner();
        const targetAddress = await signer.getAddress();
        const wei = ethers.utils.parseEther(String(qty));
        
        try {
            
            if(network==='xdai'){
                const tx = await window.xDaiBridge.bridgeOff(targetAddress,{value: wei});
                await tx.wait();
            }else {
                const allowance = await window.pusd.allowance(targetAddress, window.mintmeBridge.address);
                console.log(ethers.utils.formatEther(allowance),ethers.utils.formatEther(wei))
                const toApprove = wei.sub(allowance);
                if(toApprove.gt(0)){
                    const approval = await window.pusd.approve(window.mintmeBridge.address, toApprove);
                    await approval.wait();
                }
                const tx = await window.mintmeBridge.bridgeOff(targetAddress,wei);
                await tx.wait();
            }
            Swal.fire({
                title: 'Success!',
                text: 'Your request has been sent to the bridge, and your tokens should arive shortly (2-5min)',
                icon: 'success',
            });
        } catch(e){
            Swal.fire({
                title: 'Error!',
                text: 'Error approving transaction, please try again',
                icon: 'error',
            });
        };
        setBridiging(false);
        window.forceUpdateUI();
        
    }
}
function InputWithIcon(props) {

    const fee = window.fees[props.network];

    let [qty,setQty] = useState(1);
    let [isbridging,setBridging] = useState(false);
    let [output,setOutput] = useState(1-fee)
    
    function changeQty(event){
        const target = event.target.value;
        if(isNaN(target-0)){return;}
        setQty(target);
        setOutput(exchangeRate(target));
    }
    function changeOutput(event){
        const target = event.target.value
        if(isNaN(target-0)){return;}
        setOutput(target);
        setQty(target-0+fee)
    }
    async function useAll(){
        const signer = await props.provider.getSigner();
        /*
        */
        if(props.network==='xdai'){
            const ether = window.xDaiBalance = ethers.utils.formatEther(await (signer).getBalance());
            const target = Math.max(0,(ether-0.01).toFixed(5)-0);
            setQty(target);
            setOutput(exchangeRate(target));
        }else{
            console.log(await props.provider.getSigner())
            const pUSDBalance = window.pusdBalance = ethers.utils.formatEther(await window.pusd.balanceOf(signer.getAddress()));
            const target = Math.max(0,(pUSDBalance-0.01).toFixed(5)-0);
            setQty(target);
            setOutput(exchangeRate(target));
        }
        
        
    }
    function exchangeRate(value){
        return Math.max(0,(value-fee).toFixed(5)-0);
    }
    
    let hasFunds = (qty<=(props.network==='xdai'?window.xDaiBalance:window.pusdBalance));
    if(window.loadingContracts!=='done'){
        // setTimeout(function(){setQty(qty)},100);
    }
    let can = (exchangeRate(qty)>0)&&hasFunds&&!isbridging;

    return (
        <Box component="div" sx={{ '& > :not(style)': { m: 1 } }}>
            <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="qtyBridge">Amount</InputLabel>
                <OutlinedInput id="qtyBridge" value={qty} onChange={changeQty} startAdornment={
                    <InputAdornment position="start">
                        <img src={"/"+props.network+"_bridgelogo.png"} style={{ height: 30, width: 30,}} alt={props.network} />
                    </InputAdornment>
                } endAdornment={
                    <Button onClick={useAll}>
                        All
                    </Button>
                    // <InputAdornment position="end">
                    //     <img src={"/"+props.network+"_logo.png"} style={{ height: 30, width: 30,}} alt={props.network} />
                    // </InputAdornment>
                } label="Amount" />
            </FormControl>

            <FormControl fullWidth sx={{ m: 1 }}>
                <Box component="div" sx={{ p: 0, textAlign:'center'  }}>
                    <ArrowDownwardIcon/>
                </Box>
            </FormControl>
            
            <FormControl fullWidth sx={{ m: 1 }}>

                <InputLabel htmlFor="outputBridge">Expected Output</InputLabel>
                <OutlinedInput id="outputBridge" value={output} onChange={changeOutput} startAdornment={
                    <InputAdornment position="start">
                        <img src={"/"+window.constants.otherNetwork[props.network]+"_bridgelogo.png"} style={{ height: 30, width: 30,}} alt={props.network} />
                    </InputAdornment>
                } label="Expected Output" />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
                <Button onClick={BridgeToken(props.provider,props.network,qty,setBridging)} style={!can?{backgroundColor:isbridging?'gray':'red',color:'white'}:{}} disabled={!can} variant="contained">{
                    isbridging?'bridging...':(can?'Bridge Token':(hasFunds?('Fee is '+fee):'Insufficient funds'))
                }
                {isbridging?<CircularProgress sx={{marginLeft:'10px'}} size={24}/>:''}
                </Button>
            </FormControl>
            
        </Box>
    );
}

function WrongNetwork(props){
    return (
        <Text color="#75818D" style={{ marginBottom: 23 }}>
          Please, connect to <NetworkButton network={props.network} chainId={props.chainId} provider={props.provider}/>{' '} to accesss this part of the bridge
        </Text>
    )
}
export default function BridgeSection(props){
    var [onCorrectNetwork,setOnCorrectNetwork] = useState(false);
    const correctId = window.constants.chainIds[props.network];
    (async function(){
        if(props.provider){
            const { chainId } = await props.provider.getNetwork();
            setOnCorrectNetwork(correctId===chainId);
        }
    })();
    
    
    return (
        <div>
            {onCorrectNetwork? <InputWithIcon network={props.network} provider={props.provider} />:<WrongNetwork network={props.network} chainId={correctId} provider={props.provider}/>}
        </div>
    )
}
