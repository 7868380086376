import xDAIbridgeJSON from "./abis/XDAIBridge.sol/XDAIBridge.json";
import MINTMEbridgeJSON from "./abis/MINTMEBridge.sol/MINTMEBridge.json";
import WETHJSON from './abis/PUSD.sol/PUSD.json';
import PUSDJSON from './abis/Weth.sol/WETH.json';


const abis = {
  XDAIBridge:xDAIbridgeJSON["abi"],
  MINTMEBridge:MINTMEbridgeJSON["abi"],
  WETH:WETHJSON["abi"],
  PUSD:PUSDJSON["abi"],
};


export default abis;
