
import * as chains from './chains';
import swapAddresses from './swapAddresses.json'
import kovanSwapAddresses from './swapAddresses42.json'
import mintmeSwapAddresses from './swapAddresses24734.json'
import { addresses,deployAddresses } from '@project/contracts';
console.log(addresses,deployAddresses)

// If you add coins for a new network, make sure Weth address (for the router you are using) is the first entry

const AUTONITYCoins = [
  {
    name: "Auton",
    abbr: "AUT",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Newton",
    abbr: "NEW",
    address: "0xBd770416a3345F91E4B34576cb804a576fa48EB1",
  },
  {
    name: "Token A",
    abbr: "TA",
    address: "0xD5073808CbA7d24500aa8f659B339b87945958a6",
  },
  {
    name: "Token B",
    abbr: "TB",
    address: "0x908B8e60d149529d757F4aEd9320F246724f2b99",
  },
  {
    name: "Token C",
    abbr: "TC",
    address: "0x03c7D835CceE5d741b3f3D144eBfC5327925ECf9",
  },
  {
    name: "Token D",
    abbr: "TD",
    address: "0x90636A8bb3AD4C2168EE20CF5E6183D827Da2E91",
  }
]

const DEVNETCoins = [
  {
    name: "MintMe",
    abbr: "MINTME",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Peppermint",
    abbr: "PMINT",
    address:swapAddresses.token,
  },
  {
    name: "Peppermint USD",
    abbr: "PUSD",
    address: addresses.PUSD,
  }
]

const PARASTATECoins = [
  {
    name: "MintMe",
    abbr: "MINTME",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Token A",
    abbr: "TA",
    address: "0x4EDFE8706Cefab9DCd52630adFFd00E9b93FF116",
  },
  {
    name: "Token B",
    abbr: "TB",
    address: "0x4489D87C8440B19f11d63FA2246f943F492F3F5F",
  },
  
  {
    name: "Token C",
    abbr: "TC",
    address: "0x1d29BD2ACedBff15A59e946a4DE26d5257447727",
  },
  {
    name: "Token D",
    abbr: "TD",
    address: "0xc108a13D00371520EbBeCc7DF5C8610C71F4FfbA",
  }
]

const MAINNETCoins = [
  {
    name: "Ether",
    abbr: "ETH",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Dai",
    abbr: "DAI",
    address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  },
  {
    name: "Tether USD",
    abbr: "USDT",
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  },
]

const ROPSTENCoins = [
  {
    name: "Ether",
    abbr: "ETH",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Dai",
    abbr: "DAI",
    address: "0xad6d458402f60fd3bd25163575031acdce07538d",
  },
  {
    name: "Tether USD",
    abbr: "USDT",
    address: "0x6ee856ae55b6e1a249f04cd3b947141bc146273c",
  },
]

const KOVANCoins = [
  {
    name: "Ether",
    abbr: "ETH",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Peppermint",
    abbr: "PMINT",
    address:kovanSwapAddresses.token,
  }
]
//mintmeSwapAddresses
const MINTMECoins = [
  {
    name: "MINTME",
    abbr: "MINTME",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Peppermint",
    abbr: "PMINT",
    address:mintmeSwapAddresses.token,
  },
  {
    name: "Peppermint USD",
    abbr: "PUSD",
    address: deployAddresses.PUSD,
  },
  {
    name: "1000x Token",
    abbr: "1000x",
    address: "0x7b535379bBAfD9cD12b35D91aDdAbF617Df902B2",
    decimals: 12
  },
  {
    name:"XatteR Token",
    abbr:"XATTER",
    address:'0xB580f1dbA1c17882Fca8f6DDadA8428c9cB177fC',
    decimals:12,
  },
  {
    "name": "USDC",
    "abbr": "USDC",
    "address": "0xDcB579AA78e35e34581c72c291D493105949ac27",
    "decimals": 6
  },
  
  
  
  // {
  //   name:"Blast Token",
  //   abbr:"BLAST",
  //   address:'0x346fae1cf51D8467dA485B5173F70aDb9adA7B52',
  //   decimals:18,
  // },
  {
    name:"TREE Token",
    abbr:"TREE",
    address:'0x69a3eDdB6bE2d56E668E7DfF68DB1303e675A0F0',
    decimals: 12,
  },
  {
    "name":"SPRUG Token",
    "abbr":"SPRUG",
    "address":"0xB0c9A038C55a18F9EC4224794fA815C82313153d",
    "decimals":12
  },
  {
    name: "MemeLife",
    abbr: "MEME",
    address: "0xc277A0F8405dC5c45A55726955eb6C4d430D61c2"
  },
  {
    "name": "MintMoXMR",
    "abbr": "MINTMOXMR",
    "address": "0x3AD09254A2406B6CDf2b184479EaC284E99A72D3",
    "decimals": 12
  },
  
  
  //0x3AD09254A2406B6CDf2b184479EaC284E99A72D3
  {
      "name": "BANG Token",
      "abbr": "BANG",
      "address": "0xf8402c9E3218588EeEBb0CC08a01E841242bdEe0",
      "decimals": 12
  }
  
]

const RINKEBYCoins = [
  {
    name: "Ether",
    abbr: "ETH",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Dai",
    abbr: "DAI",
    address: "0x95b58a6bff3d14b7db2f5cb5f0ad413dc2940658",
  },
  {
    name: "Tether USD",
    abbr: "USDT",
    address: "0x3b00ef435fa4fcff5c209a37d1f3dcff37c705ad",
  },
]

const GÖRLICoins = [
  {
    name: "Ether",
    abbr: "ETH",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Dai",
    abbr: "DAI",
    address: "0x73967c6a0904aa032c103b4104747e88c566b1a2",
  },
  {
    name: "Tether USD",
    abbr: "USDT",
    address: "0x509ee0d083ddf8ac028f2a56731412edd63223b9",
  },
]

const COINS = new Map();
COINS.set(chains.ChainId.MAINNET, MAINNETCoins);
COINS.set(chains.ChainId.ROPSTEN, ROPSTENCoins);
COINS.set(chains.ChainId.RINKEBY, RINKEBYCoins);
COINS.set(chains.ChainId.GÖRLI, GÖRLICoins);
COINS.set(chains.ChainId.KOVAN, KOVANCoins);
COINS.set(chains.ChainId.MINTME, MINTMECoins);
COINS.set(chains.ChainId.AUTONITY, AUTONITYCoins);
COINS.set(chains.ChainId.DEVNET, DEVNETCoins);
COINS.set(chains.ChainId.PARASTATE, PARASTATECoins);
export default COINS