import { ethers, utils } from 'ethers';
import styled from "styled-components";
import { Text, TextBold } from './text';
const SwitchNetworkButton = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 7px;
  position: relative;
  bottom: -3px;
  cursor: pointer;
  background-color: #f3e2fd;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background-color: #f2dbff;
  }
`;
const MetamaskIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

export default function NetworkButton(props){

    const isMetamask = !props.provider || (props.provider.connection.url==='metamask');
    const name = props.network;
    const chainId = props.chainId;
    if (!isMetamask) {
        return <b>{name}</b>;
    }
    async function switchChainId(chainId){
        console.log('SWITCHING NETWORK ',chainId)
        const ethereum = window.ethereum;
        try {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: utils.hexValue(Number(chainId)) }],
            });
            console.log('requesting')
        } catch (switchError) {
            console.log(switchError)
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                await ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [window.constants.configs[name]],
                });
                } catch (addError) {
                // handle "add" error
                }
            }
        // handle other "switch" errors
        }
    }
    
    
    return (
      <SwitchNetworkButton onClick={() => switchChainId(chainId)}>
        <MetamaskIcon src="/img/metamask.svg" />
        <TextBold color="#943ACA">{name}</TextBold>
      </SwitchNetworkButton>
    );
  }