import React from "react";
import { Fab, Grid, InputBase} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import * as COLORS from "@material-ui/core/colors";
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '5px',
    minHeight: "80px",
    backgroundColor: COLORS.grey[50],
    borderRadius: '5px',
    borderColor: COLORS.grey[300],
    borderWidth: "1px",
    borderStyle: "solid",
  },
  container_input: {
    padding: '5px',
    minHeight: "68px",
    backgroundColor: COLORS.grey[50],
    borderRadius: '5px',
    borderColor: COLORS.grey[300],
    borderWidth: "1px",
    borderStyle: "solid",
    marginLeft: "50%",
    textAlign: "right",
  },
  container_blank: {
    padding: '5px',
    minHeight: "80px",
    borderRadius: '5px',
  },
  grid: {
    height: "60px",
  },
  fab: {
    zIndex: "0",
    color:"rgb(36, 36, 36)!important",
    padding:"5px"
  },
  input: {
     width: "100%",
  },
  inputBase: {
    textAlign: "left",
  },
}));

CoinField.propTypes = {
  onClick: PropTypes.func.isRequired,
  symbol: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  activeField: PropTypes.bool.isRequired,
};

export function RemoveLiquidityField1(props) {
  // This component is used to selecting a coin and entering a value, the props are explained below:
  //      onClick - (string) => void - Called when the button is clicked
  //      symbol - string - The text displayed on the button
  //      value - string - The value of the text field
  //      onChange - (e) => void - Called when the text field changes
  //      activeField - boolean - Whether text can be entered into this field or not

  const classes = useStyles();
  const { onClick, symbol, value, onChange, activeField } = props;
  return (
    <div className={classes.container_blank}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.grid}
      >
        {/* Button */}
        <Grid item xs={3}>
          <Fab
            size="small"
            variant="extended"
            onClick={onClick}
            className={classes.fab}
          >
            {symbol}
            <ExpandMoreIcon />
          </Fab>
        </Grid>
        {/* Text Field */}
        <Grid item xs={9}>
          <InputBase
            value={value}
            onChange={onChange}
            placeholder="0.0"
            disabled={!activeField}
            classes={{
              root: classes.container_input,
              input: classes.inputBase,
            }}
          />
        </Grid>
        {/* </div> */}
      </Grid>
    </div>
  );
}

export function RemoveLiquidityField2(props) {
  // This component is used to selecting a coin and entering a value, the props are explained below:
  //      onClick - (string) => void - Called when the button is clicked
  //      symbol - string - The text displayed on the button
  //      value - string - The value of the text field
  //      onChange - (e) => void - Called when the text field changes
  //      activeField - boolean - Whether text can be entered into this field or not

  const classes = useStyles();
  const { onClick, symbol } = props;

  return (
    <div className={classes.container_blank}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.grid}
      >
        {/* Button */}
        <Grid item xs={3}>
          <Fab
            size="small"
            variant="extended"
            onClick={onClick}
            className={classes.fab}
          >
            {symbol}
            <ExpandMoreIcon />
          </Fab>
        </Grid>
      </Grid>
    </div>
  );
}

export default function CoinField(props) {
  // This component is used to selecting a token and entering a value, the props are explained below:
  //      onClick - (string) => void - Called when the button is clicked
  //      symbol - string - The text displayed on the button
  //      value - string - The value of the text field
  //      onChange - (e) => void - Called when the text field changes
  //      activeField - boolean - Whether text can be entered into this field or not

  const classes = useStyles();
  const { onClick, symbol, value, onChange, activeField } = props;

  return (
    <Box component="div" sx={{ '& > :not(style)': { m: 1 } }}>
      <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor={props.label}>{props.label}</InputLabel>
            <OutlinedInput id={props.label} value={value}
              onChange={onChange}
              placeholder="0.0"
              disabled={!activeField}
              classes={{ root: classes.input, input: classes.inputBase }}
              endAdornment={
                <Grid item xs={3}>
                  <Fab
                    size="small"
                    variant="extended"
                    onClick={onClick}
                    className={classes.fab}
                  >
                    {symbol}
                    <ExpandMoreIcon />
                  </Fab>
                </Grid>
          } label={props.label} />
      </FormControl>
    </Box>
  );

  //   <div className={classes.container}>
  //     <Grid
  //       container
  //       direction="row"
  //       justifyContent="space-between"
  //       alignItems="center"
  //       className={classes.grid}
  //     >
  //       {/* Button */}
  //       <Grid item xs={3}>
  //         <Fab
  //           size="small"
  //           variant="extended"
  //           onClick={onClick}
  //           className={classes.fab}
  //         >
  //           {symbol}
  //           <ExpandMoreIcon />
  //         </Fab>
  //       </Grid>

  //       {/* Text Field */}
  //       <Grid item xs={9}>
  //         <InputBase
  //           value={value}
  //           onChange={onChange}
  //           placeholder="0.0"
  //           disabled={!activeField}
  //           classes={{ root: classes.input, input: classes.inputBase }}
  //         />
  //       </Grid>
  //     </Grid>
  //   </div>
  // );
}
