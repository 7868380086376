import addresses from './swapAddresses.json'
import kovanAddresses from './swapAddresses42.json'
import mintmeAddresses from './swapAddresses24734.json'

export const networks = [3,42,1337,24734]



export const ChainId = {
  // MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GÖRLI: 5,
  KOVAN: 42,
  DEVNET: 1337,
  AUTONITY: 444900,
  PARASTATE: 123,
  MINTME: 24734,
};
console.log(ChainId)

export const routerAddress = new Map();
// routerAddress.set(ChainId.MAINNET, "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D");
routerAddress.set(ChainId.ROPSTEN, addresses.router);
routerAddress.set(ChainId.RINKEBY, addresses.router);
routerAddress.set(ChainId.GÖRLI, addresses.router);
routerAddress.set(ChainId.KOVAN, kovanAddresses.router);
routerAddress.set(ChainId.MINTME, mintmeAddresses.router);
routerAddress.set(ChainId.DEVNET, addresses.router);
routerAddress.set(ChainId.AUTONITY, addresses.router);
routerAddress.set(ChainId.PARASTATE, addresses.router);

