import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';

import { useSnackbar } from "notistack";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import styled from "styled-components";
import { Text, TextBold } from './text';
import { ethers, utils } from 'ethers';
import Swal from 'sweetalert2'

export default function Staker(props){
    const { enqueueSnackbar } = useSnackbar();

    let [isbridging,setBridging] = useState(false);
    const hasFunds = true;
    const can = !isbridging;
    const fee = 0.1;
    const isStaking = props.coin==='pmint'
    let [qty,setQty] = useState(1);
    function changeQty(event){
        const target = event.target.value;
        if(isNaN(target-0)){return;}
        setQty(target);
    }
    
    async function BridgeToken(provider,coin,qty){
        setBridging(true);
        try {
            const signer = await props.provider.getSigner(); 
            const targetAddress = await signer.getAddress();
            const wei = ethers.utils.parseEther(qty+'');
            
            if(coin==='pmint'){
                if(wei.gt(await props.pmint.balanceOf(targetAddress))){
                    enqueueSnackbar("Insufficient Balance", {variant: "error",});
                    setBridging(false);
                    return;
                }
                const allowance = await props.pmint.allowance(targetAddress, props.bar.address);
                console.log(ethers.utils.formatEther(allowance),ethers.utils.formatEther(wei))
                const toApprove = wei.sub(allowance);
                if(toApprove.gt(0)){
                    const approval = await props.pmint.approve(props.bar.address, toApprove);
                    await approval.wait();
                }
                const tx = await props.bar.enter(wei);
                await tx.wait();
            }else if(coin==='xpmint'){
                if(wei.gt(await props.bar.balanceOf(targetAddress))){
                    enqueueSnackbar("Insufficient Balance", {variant: "error",});
                    setBridging(false);
                    return;
                }
                const tx = await props.bar.leave(wei);
                await tx.wait();
            }
            enqueueSnackbar("Transaction Successful", { variant: "success" });
        }catch(e){
            enqueueSnackbar("Transaction Failed (" + e.message + ")", {
                variant: "error",
              });
        }
        setBridging(false);
        
        return 0;
    }
    async function useAll(){
        const signer = await props.provider.getSigner();
        const adr = await signer.getAddress();
        if(isStaking){
            setQty(ethers.utils.formatEther(await props.pmint.balanceOf(adr)))
        }else{
            setQty(ethers.utils.formatEther(await props.bar.balanceOf(adr)))
        }
        
        return 0;
    }


    return (
    <Box component="div" sx={{ '& > :not(style)': { m: 1 } }}>
        <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="qtyBridge">Amount</InputLabel>
            <OutlinedInput id="qtyBridge" value={qty} onChange={changeQty} startAdornment={
                <InputAdornment position="start">
                    <img src={"/coins/"+props.coin+".png"} style={{ height: 30, width: 30,}} alt={props.coin} />
                </InputAdornment>
            } endAdornment={
                <Button onClick={useAll}>
                    All
                </Button>
            } label="Amount" />
        </FormControl>

        <FormControl fullWidth sx={{ m: 1 }}>
            <Button onClick={()=>{BridgeToken(props.provider,props.coin,qty)}} style={!can?{backgroundColor:isbridging?'gray':'red',color:'white'}:{}} disabled={!can} variant="contained">{
            isbridging?'approving..':(can?'Approve':'Insufficient funds')
            }</Button>
        </FormControl>
        
    </Box>
    )
}